import React from "react";
import { useHistory } from "react-router";
import useScrollToSection from "../../services/hooks/useScrollToSection";
import { privacyPolicies } from "../../utils/termsAndPrivacyData";

const PrivacyPolicy: React.FC = () => {
  const history = useHistory();
  useScrollToSection(history);

  return (
    <div className="bg-soft-black text-white mx-auto p-4 ">
      <div className="bg-soft-black rounded-lg flex items-center justify-between">
        <div className="relative flex justify-center items-center w-full">
          <h1 className="text-base font-bold font-primary text-left leading-tight-19.2 tracking-0.004em">PRIVACY POLICY</h1>
        </div>
      </div>
      <div className="bg-soft-black text-white mx-auto p-4 ">
        <p className="mb-4 font-secondary text-14px font-normal leading-tight-18.2 text-left">
          This Privacy Policy for Stashrun Inc. (“Company”, “we”, “us” or “our”) describes how we collect, use and disclose information
          about users of the Company’s website{" "}
          <a href="https://www.stashrun.com" className=" underline">
            www.stashrun.com
          </a>{" "}
          (the “Website”), application, services, tools, and features (collectively, the “Services”). For the purposes of this Privacy
          Policy, “you” and “your” means you as the user of the Services.
        </p>
        <p className="mb-4 font-secondary text-14px font-normal leading-tight-18.2 text-left">
          <strong>PLEASE READ THIS PRIVACY POLICY CAREFULLY.</strong> If you do not want information about you used as described in this
          Privacy Policy, please do not access or use the Services. The Services are for individuals in the United States only and are not
          intended for users located outside the United States.
        </p>
        {privacyPolicies.map((policy, index) => (
          <div key={index} id={policy.id}>
            {policy.title && <h3 className="font-bold font-secondary">{policy.title}</h3>}
            {policy.description && (
              <p className="mb-4 font-secondary text-14px font-normal leading-tight-18.2 text-left">{policy.description}</p>
            )}
            {policy.sections?.map((section, subIndex) => (
              <div key={subIndex}>
                {section.subTitle && (
                  <p className="mb-4 font-secondary text-14px font-normal leading-tight-18.2 text-left">{section.subTitle}</p>
                )}
                {section.points && (
                  <ul className="list-disc list-outside pl-6 mb-4 font-secondary">
                    {section.points.map((sectionPoint, pointIndex) => (
                      <li key={pointIndex} className="leading-relaxed font-secondary">
                        {sectionPoint.point}
                        {sectionPoint.subPoints && sectionPoint.subPoints.length > 0 && (
                          <ul className="list-disc list-outside pl-6">
                            {sectionPoint.subPoints.map((subPoint, subPointIndex) => (
                              <li key={subPointIndex} className="leading-relaxed">
                                {subPoint}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
