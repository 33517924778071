import React, { useEffect, useState } from "react";
import GameBackground from "../../../assets/images/game-star.png";
import "../../../assets/css/index.css";
import useGameBubblePop from "../hooks/useGameBubblePop";
import { emptyBubble } from "../../../common/constants";
import InstructionalModal from "./instructionModal";
import { convertToPercentage, generateCloudinaryImageUrl, getLocalStorage } from "../../../utils/common-function";
import WaitlistModal from "../../../components/waitlistModal";
import { formatCurrencyToUsd, formatTimeInSeconds } from "../../../utils/format";
import clsx from "clsx";
import { Stashlink } from "../../../types";
import ProgressBar from "../../../components/common/progressBar";
import BrandHeader from "../../../components/BrandHeader";
import Clock from "../../../assets/images/clock.svg";

const BubblePopGame: React.FC = () => {
  const {
    bubbles,
    total,
    isGameComplete,
    focusCustomBackground,
    isModalOpen,
    revealBubble,
    time,
    closeModal,
    isServerError,
    closeWaitListModal
  } = useGameBubblePop();
  const [showCoin, setShowCoin] = useState(false);
  const [key, setKey] = useState(0);
  const brandsData = getLocalStorage("brandsData");
  const stashlink = getLocalStorage("stashlink") as Stashlink;
  const campaign = stashlink?.campaign;
  const percentageCompleted = convertToPercentage(campaign.currentAmount, campaign.goalAmount);

  if (isServerError) {
    return <WaitlistModal isOpen={isServerError} onClose={closeWaitListModal} />;
  }

  const hasCustomBackground = !!stashlink?.promoBrandingImageUrl;
  const shouldHideTimer = isGameComplete || focusCustomBackground;

  useEffect(() => {
    if (showCoin) {
      const timer = setTimeout(() => {
        setShowCoin(false);
      }, 400); // Match animation duration
      return () => clearTimeout(timer);
    }
  }, [key]);

  const handleCoinClick = () => {
    // If coin is already showing, reset it
    if (showCoin) {
      setShowCoin(false);
      // Small delay to ensure state updates before showing again
      setTimeout(() => {
        setKey((prev) => prev + 1);
        setShowCoin(true);
      }, 10);
    } else {
      setShowCoin(true);
    }
  };

  return (
    <div className="flex flex-col items-center bg-soft-black text-white select-none">
      <div className={clsx(["w-full relative z-[100] flex flex-row items-center justify-between"])}>
        <BrandHeader brand={brandsData?.sampleEligibleBrands?.brand} isReady />
        {/* <div className="text-primary-l1 text-[22px] capitalize font-primary font-extrabold">${total === 0 ? "0" : total.toFixed(2)}</div>
        <div className="flex flex-row items-center gap-4">
          <div className="flex flex-col gap-0 text-right pr-20">
            <div className="text-white text-base font-medium font-secondary leading-tight">donated by</div>
            <div className="text-white text-lg font-bold font-secondary leading-tight">
              {brandsData?.sampleEligibleBrands?.brand.name || ""}
            </div>
          </div>
          <div
            onClick={() => {
              setIsModalOpen(!isGameComplete);
              mixpanel.track("brand-logo-clicked");
            }}
            className={`absolute right-2 -top-1 z-10 rounded-full bg-black w-[88px] h-[88px] flex items-center justify-center p-1 pointer-events-auto`}>
            <div className="flex bg-white object-cover rounded-full p-1 items-center justify-center">
              <ImageContainer
                src={generateCloudinaryImageUrl(brandsData?.sampleEligibleBrands?.brand.imageUrl || "", "243", "243")}
                className="rounded-full"
              />
            </div>
          </div>
        </div> */}
      </div>
      {hasCustomBackground ? (
        <div className="absolute h-screen inset-0 flex items-center justify-center z-10">
          <div
            className={clsx([
              "transition-all duration-[1000ms] absolute size-full bg-black z-10",
              focusCustomBackground ? "opacity-0" : "opacity-65"
            ])}
          />
          <img
            src={generateCloudinaryImageUrl(stashlink?.promoBrandingImageUrl || "", "585", "1266")}
            alt=""
            className={clsx([
              "w-full h-screen object-cover disable-interaction transition-all duration-[1000ms]",
              isGameComplete ? "scale-0" : "scale-100"
            ])}
          />
        </div>
      ) : null}
      <div className="h-[556px] w-full xxsh:h-c-78 flex flex-col items-center justify-between relative overflow-hidden">
        {/* <div className="flex items-center justify-between px-4 w-full relative z-20 mt-3">
          <div
            className={`${total === 0 ? "text-grey" : "text-base-white"} lining-nums tabular-nums base-white px-4 py-2 bg-grey-bg rounded-full backdrop-blur-2 min-w-28 flex items-center justify-center font-semibold text-base
             leading-tight ${shouldHideTimer ? "hide-timer" : ""}`}>
            {formatTimeInSeconds(time) + "s"}
          </div>
          <div
            className={`${total === 0 ? "bg-grey-bg" : "bg-[#d7ff3e]"} ${shouldHideTimer ? "hide-amount" : ""} h-9  min-w-32 font-primary rounded-4xl flex text-19 flex-col items-center justify-center font-extrabold capitalize lining-nums proportional-nums`}>
            <div className={`leading-tight ${total === 0 ? "text-grey" : "text-black"}`}>${total === 0 ? total : total.toFixed(2)}</div>
          </div>
        </div> */}
        <div
          id="game"
          className={clsx(["transition-all duration-[1000ms] w-full relative p-4 pt-16 flex flex-grow items-start justify-center"])}>
          <div
            className={clsx([
              "w-[556px] h-[556px] left-1/2 -translate-x-1/2 absolute inset-0 flex items-center justify-center z-10 transition-all duration-[1000ms]",
              !hasCustomBackground && !isGameComplete ? "block" : "hidden"
            ])}>
            <img src={GameBackground} alt="" className="bubble-game-background w-full object-contain disable-interaction" />
          </div>

          <div className="w-full flex flex-col z-30 scale-[85%] -translate-y-10 xs:translate-y-0 xs:scale-100">
            <div className="h-11 w-full flex justify-between z-30 mb-4">
              {total === 0 ? (
                <div className="font-secondary flex flex-row items-center justify-center gap-x-1 text-lg text-white leading-tight-18.2 w-full text-center font-medium text-shadow-game">
                  <div className="text-[34px] animate-bounce">👇</div> Tap a coin to stash!{" "}
                  <div className="text-[34px] animate-bounce">👇</div>
                </div>
              ) : (
                <div
                  className={`text-white flex flex-row items-center justify-center gap-x-1 w-fit lining-nums tabular-nums base-white px-4 py-2 bg-grey-bg rounded-full backdrop-blur-2 font-semibold text-sm
                    leading-snug ${shouldHideTimer ? "hide-timer" : ""}`}>
                  <img src={Clock} alt="clock" className="size-6" /> {formatTimeInSeconds(time) + "s"}
                </div>
              )}
              {total !== 0 && (
                <div className="relative">
                  <div
                    className={clsx(
                      "z-10",
                      "text-base font-extrabold flex flex-col items-center gap-y-0 justify-center font-primary lining-nums tabular-nums bg-grey-bg py-1 px-6 rounded-full text-grey",
                      "text-primary-l1",
                      "bg-[#374019]",
                      shouldHideTimer ? "hidden" : ""
                    )}>
                    ${total.toFixed(2)}
                    <div className="-mt-1 text-[#d7ff3e] text-[10px] font-medium font-['Inter'] leading-[14px] tracking-wide">donated</div>
                  </div>
                  {showCoin && <div key={key} className="absolute -z-20 left-1/3 bottom-5 coin coin-bounce"></div>}
                </div>
              )}
            </div>
            <div
              className={`relative z-30 grid grid-cols-7 gap-2 mb-1 game-bubble-transform ${focusCustomBackground || isGameComplete ? "game-bubble-resize" : ""}`}>
              {bubbles.map((bubble, index) =>
                emptyBubble.includes(index) ? (
                  <div key={index} className="w-10 h-10 rounded-full"></div>
                ) : (
                  <div
                    key={index}
                    onClick={() => {
                      revealBubble(index, handleCoinClick);
                    }}
                    onTouchStart={() => {
                      revealBubble(index, handleCoinClick);
                    }}
                    className={`${bubble.revealed ? "flipping revealed-coin" : "coin"}`}>
                    {bubble.revealed && (
                      <div
                        className={`w-10 h-10 rounded-full flex items-center justify-center cursor-pointer ${
                          bubble.revealed ? "bg-gray-500/30" : "bg-primary-l1/40"
                        }`}></div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>

          <div
            className={clsx([
              "absolute pt-20 flex flex-col gap-3 items-center justify-center pb-6 top- pointer-events-none z-30 transition-all duration-[1000ms]",
              !hasCustomBackground && !isGameComplete && "hidden",
              hasCustomBackground && !focusCustomBackground && "hidden",
              hasCustomBackground && focusCustomBackground && !isGameComplete && "hidden",
              hasCustomBackground && focusCustomBackground && isGameComplete && "block"
            ])}>
            <div
              className={clsx([
                "w-[556px] h-[556px] left-1/2 -translate-x-1/2 -z-10 absolute -top-16 inset-0 flex items-center justify-center transition-all duration-[1000ms]",
                hasCustomBackground && !isGameComplete && "hidden"
              ])}>
              <img src={GameBackground} alt="" className="bubble-game-background w-full object-contain disable-interaction" />
            </div>
            {/* Added the Dollar amount earn for game completion */}
            <div
              className={`flex flex-col rounded-3xl min-w-[85%] py-4 px-8 bg-[#1f1f23]/70 justify-start z-20 items-center backdrop-blur-[2px] amountEarn ${
                isGameComplete ? "final-position" : "initial-position"
              } ${isGameComplete ? "amountEarn-transformation" : ""}`}>
              <span className="text-center pb-3 self-stretch font-secondary font-bold text-lg break-words font-wght-740 leading-[130%] text-neutral-50 inline text-shadow-game">
                {" "}
                In just {formatTimeInSeconds(time)}s
              </span>
              <div
                className={`mb-3 py-2 px-4 bg-[#d7ff3e] rounded-[999px] text-center text-black text-32px font-extrabold font-primary flex justify-center items-center capitalize leading-[33.76px] ${
                  isGameComplete ? "amountEarn-transformation" : ""
                }`}>
                $1.00
              </div>
              <div className="text-center">
                <span className="self-stretch justify-center font-secondary text-sm text-white leading-[21px] font-medium inline text-shadow-game">
                  was donated to
                </span>
                <br />
                <span className="self-stretch font-secondary font-bold text-lg break-words font-wght-740 leading-[130%] text-neutral-50 inline text-shadow-game">
                  {" "}
                  {campaign?.title}
                </span>
              </div>
              <div className="w-full flex flex-col mt-5">
                <ProgressBar percentageCompleted={percentageCompleted} />
                <p className="text-neutral-50 font-primary text-22px text-center font-bold leading-[24.75px] tracking-[0.22px] mt-2 pb-1">
                  {formatCurrencyToUsd(stashlink?.campaign?.currentAmount)}{" "}
                  <span className="font-secondary text-14px font-normal leading-[18.2px]">
                    raised of {formatCurrencyToUsd(stashlink?.campaign?.goalAmount)}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <p className="font-secondary mt-5 text-base text-white leading-tight font-normal">Every cent you stash is donated by </p>
      <h1 className="font-primary capitalize text-center text-28 font-extrabold mt-2 leading-tight-29.54 text-neutral-50 mx-1">
        {brandsData?.sampleEligibleBrands?.brand.name || ""}
      </h1> */}
      <InstructionalModal brandName={brandsData?.sampleEligibleBrands?.brand.name || ""} isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default BubblePopGame;
