import { GrowthBook } from "@growthbook/growthbook-react";

export const Constants = {
  sizeMap: {
    xs: "7",
    s: "12",
    m: "20",
    l: "32",
    xl: "40"
  }
};

export const inputType = {
  text: "text",
  password: "password",
  file: "file",
  date: "date"
};

export const maxGamePlayCount = 2;

// export const emptyBubble = [16, 17, 18, 23, 24, 25, 30, 31, 32];
export const emptyBubble = [] as number[];
export const gameDefaultAmounts = [0.01, 0.05, 0.1, 0.2, 0.25, 0.5];
export const oneDollar = "1.00";
export const gameType = {
  bubblePop: "bubble_pop"
};

export const timeout = {
  halfSecond: 500,
  fifteenHundred: 1500,
  eightHundred: 800,
  twoThousand: 2000,
  twoThousandSixHundred: 2600,
  threeThousand: 3000,
  fourThousand: 4000,
  fourThousandFiveHundred: 4500,
  fiveThousand: 5000,
  fiveThousandFiveHundred: 5500,
  sixThousand: 6000
};

export const fifteenSecondDivisions = 6.66666667;

export const socialMediaIconSize = {
  height: "39",
  width: "38"
};

export const transparentPixel = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

export const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_ENABLE_DEV_MODE === "true"
});

//Production var
export const envVariables = {
  staticStashlinkId: process.env.REACT_APP_DEFAULT_STASHLINK_ID,
  apiEndPoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  domainUrl: process.env.REACT_APP_SUPABASE_REDIRECT_URL,
  supabaseUrl: process.env.REACT_APP_SUPABASE_CALLBACK_URL || "",
  supabaseAnonKey: process.env.REACT_APP_SUPABASE_ANON_KEY || ""
};
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";

export const uploadPhoto = {
  updateAPIErrorMessage: "An error occurred while updating your profile. Please try again.",
  imageTypeMessage: "Please select a valid image file (JPEG, PNG, GIF, or WebP).",
  validFileExtensions: ["image/jpeg", "image/png", "image/gif", "image/webp"],
  defaultImageName: "profile-image",
  dimensions: {
    width: 1080,
    height: 1080
  }
};
