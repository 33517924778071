import mixpanel from "mixpanel-browser";
import React, { useState, useEffect } from "react";
import { Stashlink } from "../../types";
import clsx from "clsx";

interface CopyButtonProps {
  stashlink?: Stashlink;
  shareUrl: string;
  onCopy?: () => void;
  copyOnStart?: boolean;
}

const CopyButton = ({ stashlink, shareUrl, onCopy, copyOnStart = false }: CopyButtonProps) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState<boolean>(false);

  const copyLink = (shareUrl: string) => {
    if (isCopied) return;

    onCopy?.();

    mixpanel.track("copy-link-button-clicked", {
      charityName: stashlink?.campaign?.charity?.name,
      shareUrl
    });

    setIsAnimating(true);
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setIsAnimating(true);
        setTimeout(() => {
          setShowCopied(true);
          setTimeout(() => {
            setIsCopied(true);
            setIsAnimating(false);
            setTimeout(() => {
              setShowCheckmark(true);
            }, 500);
          }, 0);
        }, 0);
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
        setIsAnimating(false);
        setShowCopied(false);
        setIsCopied(false);
      });
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopied) {
      timeout = setTimeout(() => {
        setIsAnimating(true);
        // Just fade out, no slide
        setTimeout(() => {
          setIsCopied(false);
          setIsAnimating(false);
          setShowCopied(false);
          setShowCheckmark(false);
        }, 500);
      }, 2500);
    }
    return () => clearTimeout(timeout);
  }, [isCopied]);

  useEffect(() => {
    if (copyOnStart) {
      copyLink(shareUrl);
    }
  }, [copyOnStart]);

  return (
    <div className="relative h-32 w-[91px] overflow-hidden">
      {/* Original "Copy" button */}
      <div
        className={`absolute w-full h-full flex items-center justify-center
          transition-all duration-500 transform
          ${isAnimating && !isCopied ? "-translate-x-full opacity-0" : "translate-x-0 opacity-100"}
          ${isCopied ? "hidden" : ""}`}>
        <button
          onClick={() => copyLink(shareUrl)}
          className="flex flex-row justify-center items-center w-[91px] h-10 pl-2 pr-3 py-2 rounded-lg gap-1
            bg-[#90a8c1]/30
            transition-colors duration-200">
          <div className="flex flex-row justify-center items-center gap-1 text-neutral-50 text-xs font-bold font-['Inter']">
            <div data-svg-wrapper>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_15776_2243)">
                  <path
                    d="M6.89063 8.55564C7.12921 8.8746 7.4336 9.13852 7.78316 9.3295C8.13271 9.52048 8.51924 9.63404 8.91654 9.6625C9.31385 9.69095 9.71262 9.63363 10.0858 9.49441C10.459 9.3552 10.7979 9.13735 11.0795 8.85564L12.7462 7.18898C13.2522 6.66508 13.5322 5.96341 13.5258 5.23509C13.5195 4.50676 13.2274 3.81006 12.7123 3.29504C12.1973 2.78002 11.5006 2.48788 10.7723 2.48155C10.044 2.47522 9.3423 2.75521 8.81841 3.2612L7.86285 4.2112M9.11285 7.44453C8.87427 7.12557 8.56987 6.86165 8.22032 6.67068C7.87077 6.4797 7.48423 6.36614 7.08693 6.33768C6.68963 6.30923 6.29086 6.36655 5.91766 6.50577C5.54446 6.64498 5.20556 6.86283 4.92396 7.14453L3.25729 8.8112C2.7513 9.3351 2.47132 10.0368 2.47764 10.7651C2.48397 11.4934 2.77611 12.1901 3.29113 12.7051C3.80615 13.2202 4.50286 13.5123 5.23118 13.5186C5.9595 13.525 6.66118 13.245 7.18507 12.739L8.13507 11.789"
                    stroke="#FAFAFA"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_15776_2243">
                    <rect width="13.3333" height="13.3333" fill="white" transform="translate(1.33301 1.3335)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            Copy
          </div>
        </button>
      </div>

      {/* "Copied" button */}
      {showCopied && (
        <div
          className={`absolute w-full h-full flex items-center justify-center
            transition-all duration-500 transform rounded-lg
            ${isAnimating && isCopied ? "opacity-0" : "opacity-100"}
            ${!isCopied ? "translate-x-full" : "translate-x-0"}`}>
          <button
            className={clsx([
              "py-3 bg-[#374110] text-[#d7ff3e] rounded-lg transition-colors duration-200 flex flex-row justify-center items-center gap-1 text-xs font-bold font-['Inter']",
              showCheckmark ? "px-2" : "px-3"
            ])}>
            {showCheckmark && (
              <div data-svg-wrapper>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.333 3.6665L6.66634 11.6665L3.33301 8.33317"
                    stroke="#D7FF3E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
            Copied!
          </button>
        </div>
      )}
    </div>
  );
};

export default CopyButton;
