import { ApolloProvider } from "@apollo/client";
import React, { useEffect } from "react";
import apolloClient from "../services/apollo-client";
import { ErrorBoundary } from "react-error-boundary";
import NotFound from "../components/common/notFound";

const App: React.FC<IProps> = ({ children }) => {
  useEffect(() => {
    document.addEventListener("gesturestart", function (event) {
      event.preventDefault();
    });
    return () => {
      document.removeEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    };
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <ErrorBoundary fallback={<NotFound />}>
        <div>{children}</div>
      </ErrorBoundary>
    </ApolloProvider>
  );
};

export interface IProps {
  pending?: boolean;
  children: React.ReactNode;
}

export default App;
