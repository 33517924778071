import React from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import ShareIconGreenOutline from "../../assets/images/shareIconGreenOutline.svg";
import RetryIcon from "../../assets/images/retry.svg";
import { ContinueModalProps } from "./types";
import { formatUrlWithProtocol, generateCloudinaryImageUrl, getLocalStorage } from "../../utils/common-function";
import mixpanel from "../../services/mixpanelService";
import useColinkExist from "../../services/hooks/useColinkExist";
import { SampleEligibleBrandsData, Stashlink } from "../../types";
import FollowingCheck from "../../assets/images/following-check.svg";
import ExternalLinkIcon from "./../../assets/images/take-action-external-link.png";
import { routes } from "../../routes/routes.constant";
import { useHistory } from "react-router";
import { maxGamePlayCount } from "../../common/constants";

const ContinueModal: React.FC<ContinueModalProps> = (props: ContinueModalProps) => {
  const { isOpen, onClose, campaign, openShareModal, navigateToStashAgain, navigateToColink } = props;
  const gamePlayCount = getLocalStorage("gamePlayCount") as number;
  const stashlink = getLocalStorage("stashlink") as Stashlink;
  const userId = getLocalStorage("userId") as string;
  const brandsData = getLocalStorage("brandsData") as SampleEligibleBrandsData;
  const onboardingFeatureFlag = useFeatureIsOn("onboarding-flow");
  const { colinkExistData } = useColinkExist(stashlink?.id || "");
  const navigation = useHistory();
  if (!isOpen) return null;

  const playAgainButton = (
    <button
      onClick={() => {
        const hasOnlyOneBrand = brandsData?.sampleEligibleBrands?.topEligibleBrands?.length === 1;
        mixpanel.track("continue-modal-play-again-clicked", {
          redirectTo: hasOnlyOneBrand ? "brand" : "stashlink"
        });
        if (hasOnlyOneBrand) {
          navigation.push({
            pathname: routes.sponsorDetails,
            state: { from: location.pathname, fromValidFlow: true, showMatchModal: false }
          });
        } else {
          navigateToStashAgain();
        }
      }}
      className="w-full h-[78px] gap-x-4 p-4 items-center justify-center flex flex-row bg-grey/20 rounded-xl border-t-2 border-b-4 border-x-2 border-[#90a8c1]/70 tracking-0.56 text-base-white text-sm leading-tight-16.8">
      <img src={RetryIcon} alt="Play again" />
      <div className="flex flex-col">
        <div className="text-left font-bold font-primary text-sm mb-0.5 text-neutral-50 select-none">Play again</div>
        <div className="text-left text-xs text-light-grey select-none">Raise more money when you play a second time.</div>
      </div>
    </button>
  );

  const returnToCampaignButton = (
    <div
      onClick={() => {
        mixpanel.track("continue-modal-return-clicked");
        navigateToStashAgain();
      }}
      className="w-full gap-x-2 py-4 px-4 items-center justify-center flex flex-row rounded-xl tracking-0.56 text-base-white text-sm leading-tight-16.8">
      <div className="flex flex-col">
        <div className="text-center font-medium font-primary text-base select-none">Back to campaign</div>
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-modal-bg/80 flex items-center justify-center z-20 backdrop-blur-5" onClick={onClose}>
      <div className="text-base-white rounded-3xl w-[90%] relative px-7 py-5 bg-black">
        <div className="space-y-3">
          <div className="font-primary font-bold text-lg w-full text-center leading-snug text-neutral-50 select-none pb-1">
            More ways to help
          </div>
          <button
            className="w-full h-[78px] gap-x-4 bg-soft-black p-4 text-sm rounded-xl text-primary-l1 border-primary-l1 border-t-2 border-b-4 border-x-2 border-grey/70 flex align-center justify-center tracking-wide leading-tight-16.8 items-center"
            onClick={() => {
              onClose();
              openShareModal();
              mixpanel.track("continue-modal-share-clicked");
            }}>
            <img src={ShareIconGreenOutline} alt="Share" />
            <div className="flex flex-col">
              <div className="text-left font-bold mb-0.5 font-primary text-sm select-none">Share</div>
              <div className="text-left text-xs text-primary-l1-light select-none">
                Sharing to your social networks raises up to <b>5x more</b>
              </div>
            </div>
          </button>
          {!onboardingFeatureFlag ? <>{gamePlayCount < maxGamePlayCount ? playAgainButton : null}</> : playAgainButton}
          {onboardingFeatureFlag && (
            <div>
              {colinkExistData || stashlink.user.id === userId ? (
                <div>
                  <button
                    onClick={() => mixpanel.track("team-up-button-clicked")}
                    className="w-full h-[78px] gap-x-4 p-4 items-center justify-center flex flex-row bg-grey/20 rounded-xl border-t-2 border-b-4 border-x-2 border-[#90a8c1]/70 tracking-0.56 text-base-white text-sm leading-tight-16.8 select-none">
                    <div className="text-center font-bold font-primary text-base">Teamed up</div>
                    <span>
                      <img src={FollowingCheck} alt="Following Check"></img>
                    </span>
                  </button>
                </div>
              ) : (
                <button
                  className="w-full h-[78px] gap-x-4 p-4 items-center justify-center flex flex-row bg-grey/20 rounded-xl border-t-2 border-b-4 border-x-2 border-[#90a8c1]/70 tracking-0.56 text-base-white text-sm leading-tight-16.8"
                  onClick={navigateToColink}>
                  <img
                    src={generateCloudinaryImageUrl(
                      stashlink?.parentStashlink
                        ? (stashlink.parentStashlink.user?.profilePhoto ?? "")
                        : (stashlink.user?.profilePhoto ?? ""),
                      "68",
                      "68"
                    )}
                    width="48px"
                    height="48px"
                    alt="Logo"
                    className="rounded-full pure-black border-[1px] border-solid border-[#90A8C1]/15"
                  />
                  <div className="flex flex-col">
                    <div className="text-left font-bold font-primary text-sm mb-0.5 text-neutral-50 select-none">Team up</div>
                    <div className="text-left text-xs text-light-grey select-none">
                      Create a stashlink together with{" "}
                      {stashlink?.parentStashlink ? stashlink.parentStashlink.user?.fullName : stashlink.user?.fullName} to raise more.
                    </div>
                  </div>
                </button>
              )}
            </div>
          )}
          {campaign?.callToActionUrl?.length ? (
            <button
              onClick={() => {
                mixpanel.track("take-action-clicked", {
                  location: "thank-you-page",
                  campaignName: campaign?.title,
                  charityName: campaign?.charity?.name
                });
                window.open(formatUrlWithProtocol(campaign?.callToActionUrl), "_blank");
              }}
              className="w-full h-[78px] gap-x-4 p-4 items-center justify-center flex flex-row bg-grey/20 rounded-xl border-t-2 border-b-4 border-x-2 border-[#90a8c1]/70 tracking-0.56 text-base-white text-sm leading-tight-16.8">
              <img
                src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "68", "68")}
                alt="Logo"
                width="48px"
                height="48px"
                className="rounded-full pure-black border-[1px] border-solid border-[#90A8C1]/15"
              />
              <div className="flex flex-col">
                <div className="text-left w-full font-bold font-primary text-sm text-neutral-50 flex justify-between mb-0.5 select-none">
                  Take action
                  <div>
                    <img src={ExternalLinkIcon} alt={ExternalLinkIcon} className="w-4 h-4" />
                  </div>
                </div>
                <div className="text-left text-xs text-light-grey select-none">Discover other ways to support this cause.</div>
              </div>
            </button>
          ) : null}
          {gamePlayCount >= maxGamePlayCount ? returnToCampaignButton : null}
        </div>
      </div>
    </div>
  );
};

export default ContinueModal;
