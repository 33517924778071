import { RxCross2 } from "react-icons/rx";
import { SharePickerProps } from "./ShareComponent.types";
import ShareFacebook from "./../../assets/images/ShareFacebook.svg";
import ShareInstagram from "./../../assets/images/ShareInstagram.svg";
import ShareWhatsapp from "./../../assets/images/ShareWhatsapp.svg";
import ShareX from "./../../assets/images/ShareX.svg";
import ShareEmail from "./../../assets/images/ShareEmail.svg";
import ShareQR from "./../../assets/images/ShareQR.svg";
import ShareSMS from "./../../assets/images/ShareSMS.svg";
import mixpanel from "../../services/mixpanelService";
import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import { SHARE_STASHLINK } from "../../queries";
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton } from "react-share";
import ShareQRCode from "../common/shareQRCode/shareQRCode";
import CopyButton from "./CopyButton";

const SharePicker = ({ onClosePicker, onClose, shareUrl, stashlink, sendSMS, stashUser, coLinkUser }: SharePickerProps) => {
  const [shareStashlink] = useMutation(SHARE_STASHLINK);
  const [showQr, setShowQr] = useState<boolean>(false);

  const copyLink = (shareUrl: string) => {
    navigator.clipboard.writeText(shareUrl).catch((error) => {
      console.error("Failed to copy: ", error);
    });
  };

  const shareFn = useCallback(
    (type: string) => {
      shareStashlink({
        variables: {
          stashlinkId: stashlink?.id
        }
      });
      mixpanel.track("share-option-clicked", {
        charityName: stashlink?.campaign?.charity?.name,
        shareUrl,
        type
      });
    },
    [stashlink?.id]
  );

  const shareOptions = [
    {
      name: "Copy Link",
      ComponentWrapper: () => (
        <div className="h-12 pl-4 pr-1 w-full py-2 bg-black/10 rounded-xl border-l border-r border-t border-b-3 border-[#90a8c1]/30 justify-center items-center gap-2 inline-flex">
          <div className="grow shrink basis-0 flex-col justify-center items-center inline-flex">
            <div className="self-stretch text-[#b0c1d3] text-[10px] font-normal font-['Inter'] leading-3">Your unique link</div>
            <div className="self-stretch text-neutral-50 text-sm font-normal font-['Inter'] leading-[18.20px]">
              {shareUrl.slice(0, 30)}...
            </div>
          </div>
          <CopyButton stashlink={stashlink} shareUrl={shareUrl} onCopy={() => shareFn("Copy Link")} />
        </div>
      )
    },
    {
      name: "Instagram Story",
      ComponentWrapper: () => (
        <div
          onClick={() => {
            copyLink(shareUrl || "");
            onClosePicker();
          }}
          className="flex flex-row gap-4 items-center px-2">
          <div>
            <img width="40px" height="40px" src={ShareInstagram} alt="Instagram" className="max-w-[40px] object-contain" />
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-semibold text-base-white font-primary">Instagram Story</p>
          </div>
        </div>
      )
    },
    {
      name: "Instagram",
      ComponentWrapper: () => (
        <div
          onClick={() => {
            copyLink(shareUrl || "");
            onClosePicker();
          }}
          className="flex flex-row gap-4 items-center px-2">
          <div>
            <img width="40px" height="40px" src={ShareInstagram} alt="Instagram" className="max-w-[40px] object-contain" />
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-semibold text-base-white font-primary">Instagram</p>
          </div>
        </div>
      )
    },
    {
      name: "Facebook",
      ComponentWrapper: () => (
        <FacebookShareButton url={shareUrl || ""}>
          <div
            onClick={() => {
              copyLink(shareUrl || "");
              shareFn("Facebook");
            }}
            className="flex flex-row gap-4 items-center px-2">
            <div>
              <img width="40px" height="40px" src={ShareFacebook} alt="Facebook" className="max-w-[40px] object-contain" />
            </div>
            <div className="flex flex-col">
              <p className="text-sm font-semibold text-base-white font-primary">Facebook</p>
            </div>
          </div>
        </FacebookShareButton>
      )
    },
    {
      name: "Whatsapp",
      ComponentWrapper: () => (
        <WhatsappShareButton url={shareUrl || ""} title={stashlink?.campaign?.title || ""} separator=":: ">
          <div
            onClick={() => {
              copyLink(shareUrl || "");
              shareFn("Whatsapp");
            }}
            className="flex flex-row gap-4 items-center px-2">
            <div>
              <img width="40px" height="40px" src={ShareWhatsapp} alt="Whatsapp" className="max-w-[40px] object-contain" />
            </div>
            <div className="flex flex-col">
              <p className="text-sm font-semibold text-base-white font-primary">Whatsapp</p>
            </div>
          </div>
        </WhatsappShareButton>
      )
    },
    {
      name: "SMS",
      ComponentWrapper: () => (
        <div
          onClick={() => {
            copyLink(shareUrl || "");
            shareFn("SMS");
            sendSMS();
          }}
          className="flex flex-row gap-4 items-center px-2">
          <div>
            <img width="40px" height="40px" src={ShareSMS} alt="SMS" className="max-w-[40px] object-contain" />
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-semibold text-base-white font-primary">SMS</p>
          </div>
        </div>
      )
    },
    {
      name: "Email",
      ComponentWrapper: () => (
        <a
          href={`mailto:?subject=${stashlink?.campaign?.title || ""}&body=${shareUrl || ""}`}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            copyLink(shareUrl || "");
            shareFn("Email");
          }}
          className="flex flex-row gap-4 items-center px-2">
          <div>
            <img width="40px" height="40px" src={ShareEmail} alt="Email" className="max-w-[40px] object-contain" />
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-semibold text-base-white font-primary">Email</p>
          </div>
        </a>
      )
    },
    {
      name: "X",
      ComponentWrapper: () => (
        <TwitterShareButton url={shareUrl || ""} title={stashlink?.campaign?.title || ""}>
          <div
            onClick={() => {
              copyLink(shareUrl || "");
              shareFn("X");
            }}
            className="flex flex-row gap-4 items-center px-2">
            <div>
              <img width="40px" height="40px" src={ShareX} alt="X" className="max-w-[40px] object-contain" />
            </div>
            <div className="flex flex-col">
              <p className="text-sm font-semibold text-base-white font-primary">X</p>
            </div>
          </div>
        </TwitterShareButton>
      )
    },
    {
      name: "QR Code",
      ComponentWrapper: () => (
        <div
          onClick={() => {
            copyLink(shareUrl || "");
            shareFn("QR Code");
            setShowQr(true);
          }}
          className="flex flex-row gap-4 items-center px-2">
          <div>
            <img width="40px" height="40px" src={ShareQR} alt="QR Code" className="max-w-[40px] object-contain" />
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-semibold text-base-white font-primary">QR Code</p>
          </div>
        </div>
      )
    }
  ];

  if (!stashlink) return null;

  return (
    <div className="rounded-t-2xl">
      <header className="text-center pt-4 px-2 pb-6 flex flex-col">
        <div className="flex flex-col justify-center items-center">
          <p className="text-xl font-bold font-primary text-base-white leading-normal">Help by sharing</p>
          <p className="text-sm text-base-white font-secondary mt-1.5 px-4 py-1 w-fit">
            Fundraisers shared on social networks <b>raise up to 5x more</b>. Share using your unique links below.
          </p>
        </div>
        <button
          onClick={() => {
            onClose();
          }}
          className="absolute right-4 text-grey text-lg hover:text-gray-100 font-semibold z-10">
          <RxCross2 />
        </button>
      </header>
      {showQr && (
        <>
          {coLinkUser ? (
            <ShareQRCode
              stashUser={stashUser}
              coLinkUser={coLinkUser}
              charityName={stashlink?.campaign?.charity?.name}
              charityLogo={stashlink?.campaign?.charity?.imageUrl}
              shareUrl={shareUrl}
              isOpen={showQr}
              onClose={() => setShowQr(false)}
            />
          ) : (
            <ShareQRCode
              stashUser={stashUser}
              charityName={stashlink?.campaign?.charity?.name}
              charityLogo={stashlink?.campaign?.charity?.imageUrl}
              shareUrl={shareUrl}
              isOpen={showQr}
              onClose={() => setShowQr(false)}
            />
          )}
        </>
      )}
      <section className="flex flex-col gap-4 px-5 gap-y-6">
        {shareOptions.map((option) => (
          <div key={option.name}>{option.ComponentWrapper()}</div>
        ))}
      </section>
    </div>
  );
};

export default SharePicker;
