const SpinnerLoader = () => (
  <div className="pl scale-50">
    <div className="pl__dot">
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
    </div>
    <div className="pl__dot">
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
    </div>
    <div className="pl__dot">
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
    </div>
    <div className="pl__dot">
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
    </div>
    <div className="pl__dot">
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
    </div>
    <div className="pl__dot">
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
      <div className="pl__dot-layer"></div>
    </div>
  </div>
);

export default SpinnerLoader;
