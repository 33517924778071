import { useEffect, useRef, useState } from "react";
import { ShareModalProps } from "../ShareComponent.types";
import mixpanel from "../../../services/mixpanelService";
import html2canvas from "html2canvas";

const useShareModal = (props: ShareModalProps) => {
  const imageRef = useRef<HTMLDivElement>(null);
  const [imgSrc, setImgSrc] = useState<string>("");

  useEffect(() => {
    mixpanel.track("share-modal-viewed");
  }, []);

  useEffect(() => {
    //Html2Canvas code
    const convertToImage = async () => {
      if (imageRef.current) {
        try {
          const canvas = await html2canvas(imageRef.current, {
            backgroundColor: null, // Ensures transparent background
            scale: 4,
            useCORS: true // Handle cross-origin images
          });
          const img = canvas.toDataURL("image/png");
          setImgSrc(img);
        } catch (error) {
          console.error("Error converting canvas to image:", error);
        }
      }
    };

    if ((props.coLinkUser?.profilePhoto || props.stashUser.profilePhoto) && props.charityLogo) {
      // Delay convertToImage by 500ms
      const timeoutId = setTimeout(() => {
        convertToImage();
      }, 500);
      // Clean up timeout if component unmounts before the function runs
      return () => clearTimeout(timeoutId);
    }
  }, [props.stashUser, props.coLinkUser, props.charityLogo]);

  return {
    imageRef,
    imgSrc
  };
};

export default useShareModal;
