import { addCloudinaryTransformation } from "../../../utils/format";
import SpinnerLoader from "../../spinnerLoader";
import { SponsorModalProps } from "./sponsor.types";
import React, { useEffect, useState } from "react";

const SponsorModal: React.FC<SponsorModalProps> = ({ sponsorName, sponsorImageUrl }) => {
  const brandLogo = addCloudinaryTransformation(sponsorImageUrl, "c_scale,w_100,dpr_2");

  const [animationState, setAnimationState] = useState("initial");

  useEffect(() => {
    const hideLoadingTimer = setTimeout(() => {
      setAnimationState("hiding");
    }, 2500);

    const showFinalTimer = setTimeout(() => {
      setAnimationState("final");
    }, 3500);

    return () => {
      clearTimeout(hideLoadingTimer);
      clearTimeout(showFinalTimer);
    };
  }, []);

  return (
    <div className="h-screen w-full flex items-center justify-center bg-[#1f1f23]">
      <div className="flex flex-col items-center">
        {/* Loading spinner / Logo container */}
        <div className="relative w-[124px] h-[124px] mb-6">
          {/* Loading spinner */}
          <div
            className={`absolute inset-0 mb-8 flex items-center justify-center transition-all duration-500 ${
              animationState !== "initial" ? "scale-0 opacity-0" : "scale-[0.6] opacity-100"
            }`}>
            <SpinnerLoader />
          </div>

          {/* Logo */}
          <div
            className={`border-soft-black border-8 rounded-full absolute inset-0 flex items-center justify-center transition-all duration-500 ${
              animationState === "final" ? "scale-100 opacity-100" : "scale-0 opacity-0"
            }`}>
            <div className={`w-full h-full rounded-full border-base-white border-2 overflow-hidden`}>
              {/*             TODO: For Gif pass brandAnimation instead brandLogo and remove className circle */}
              <img src={brandLogo} alt="" className={`w-full h-full`} />{" "}
            </div>
          </div>
        </div>

        {/* Text container */}
        <div className="relative h-20 w-screen">
          {/* Initial loading text */}
          <div
            className={`absolute inset-0 flex w-full flex-col items-center transition-all duration-500 ${
              animationState === "initial" ? "opacity-100" : "opacity-0"
            }`}>
            <div
              className={`transition-all duration-500 text-center ${animationState !== "initial" ? "-translate-x-full" : "translate-x-0"}`}>
              <h2 className="tracking-wider">Finding a sponsor...</h2>
            </div>
            <div
              className={`transition-all w-full duration-500 text-center mt-2 ${animationState !== "initial" ? "translate-x-full" : "translate-x-0"}`}>
              <p className="font-normal w-full text-base font-Inter text-primary-l1">They’ll donate 100% of the money</p>
            </div>
          </div>

          {/* Final welcome text */}
          <div
            className={`absolute inset-0 flex w-full flex-col items-center transition-all duration-500 ${
              animationState === "final" ? "opacity-100" : "opacity-0"
            }`}>
            <div className={`transition-all duration-500 text-center ${animationState === "final" ? "translate-x-0" : "translate-x-full"}`}>
              <h2 className="tracking-wider">{sponsorName}</h2>
            </div>
            <div
              className={`transition-all w-full duration-500 text-center mt-1 ${animationState === "final" ? "translate-x-0" : "-translate-x-full"}`}>
              <p className="font-normal w-full text-base font-Inter text-primary-l1">will pay for this donation</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorModal;
