import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import useCampaign from "./useCampaign";
import { routes } from "../../../routes/routes.constant";
import { timeout } from "../../../common/constants";
import { getLocalStorage, setLocalStorage } from "../../../utils/common-function";
import { v4 as uuid } from "uuid";
import useStashLinkHooks from "./useStashLink";
import mixpanel from "../../../services/mixpanelService";

const useCampaignProfile = () => {
  const { stashlinkId } = useParams<{ stashlinkId: string }>();
  const { getStashLink, stashlink, stashlinkLoading } = useStashLinkHooks();
  const {
    isServerError,
    eligibleBrandsData,
    fetchEligibleBrands,
    eligibleBrandsLoading,
    fetchCampaignAttempts,
    campaignAttemptsData,
    campaignAttemptsLoading,
    setServerError
  } = useCampaign();
  const [isMatchSponsor, setIsMatchSponsor] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const navigation = useHistory();
  const user = stashlink?.user;
  const campaign = stashlink?.campaign;

  const fetchBrands = async () => {
    mixpanel.track("play-to-fund-button-clicked", { campaignName: campaign?.title });
    await fetchEligibleBrands({
      variables: {
        campaignId: campaign?.id,
        userIdentifier: currentUserId
      }
    });
    if (!isServerError) {
      // In case we want to bring back the logic to check if there are more than 1 eligible brands
      // && (response?.data?.sampleEligibleBrands?.topEligibleBrands?.length ?? 0) > 1) {
      setIsMatchSponsor(true);
    }
  };

  useEffect(() => {
    if (eligibleBrandsData) {
      setLocalStorage("brandsData", eligibleBrandsData);
      setLocalStorage("userData", user);
      setLocalStorage("campaign", campaign);
      setLocalStorage("stashLink", `/stashlink/${stashlinkId}`);

      navigation.push({ pathname: routes.sponsorDetails, state: { from: location.pathname, fromValidFlow: true } });
      const timer = setTimeout(() => {
        setIsMatchSponsor(false);
      }, timeout.fiveThousandFiveHundred);
      return () => clearTimeout(timer);
    }
  }, [eligibleBrandsData]);

  useEffect(() => {
    mixpanel.track("campaign-page-viewed", {
      campaignName: campaign?.title,
      charityName: campaign?.charity?.name
    });
    const loggedInUserId = getLocalStorage("userId");
    const generatedUserId = getLocalStorage("generatedUserId");

    if (loggedInUserId) {
      setCurrentUserId(loggedInUserId);
      setLocalStorage("generatedUserId", null);
    } else if (generatedUserId) {
      setCurrentUserId(generatedUserId);
    } else {
      const generatedUserId = uuid();
      setCurrentUserId(generatedUserId);
      setLocalStorage("generatedUserId", generatedUserId);
    }

    const gamePlayCount = getLocalStorage("gamePlayCount") as number;
    if (!gamePlayCount) {
      setLocalStorage("gamePlayCount", 0);
    }
  }, []);

  useEffect(() => {
    if (campaign?.id) {
      fetchCampaignAttempts({
        variables: {
          campaignId: campaign?.id
        }
      });
    }
  }, [campaign?.id]);

  useEffect(() => {
    const loggedInUserId = getLocalStorage("userId");
    const gamePlayCount = getLocalStorage("gamePlayCount") as number;
    if (campaignAttemptsData && loggedInUserId) {
      const actualPlayCount =
        campaignAttemptsData.numAttemptsToSupportCampaign > gamePlayCount
          ? campaignAttemptsData.numAttemptsToSupportCampaign
          : gamePlayCount;
      setLocalStorage("gamePlayCount", actualPlayCount);
    }
  }, [campaignAttemptsData]);

  useEffect(() => {
    getStashLink({
      variables: {
        stashlinkId: stashlinkId
      }
    });
  }, [getStashLink]);

  useEffect(() => {
    if (isServerError) {
      setIsMatchSponsor(false);
    }
  }, [isServerError]);

  return {
    eligibleBrandsData,
    eligibleBrandsLoading,
    isMatchSponsor,
    campaignAttemptsLoading,
    fetchBrands,
    stashlink,
    isServerError,
    stashlinkLoading,
    setServerError
  };
};

export default useCampaignProfile;
