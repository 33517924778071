import React, { useEffect } from "react";
import SponsorModal from "../../components/common/sponsorModal/sponsorModal";
import mixpanel from "../../services/mixpanelService";
interface MatchSponsorProps {
  sponsorImageUrl?: string;
  brandName: string;
  eligibleBrands?: {
    id: string;
    name: string;
    imageUrl: string;
  }[];
}

const MatchSponsor: React.FC<MatchSponsorProps> = ({ sponsorImageUrl, brandName, eligibleBrands }) => {
  useEffect(() => {
    mixpanel.track("brand-modal-viewed");
  }, []);

  return (
    <div className="justify-center items-center">
      <SponsorModal sponsorName={brandName} sponsorImageUrl={sponsorImageUrl || ""} eligibleBrands={eligibleBrands} />
    </div>
  );
};

export default MatchSponsor;
