import React from "react";
import { addCloudinaryTransformation } from "../../../utils/format";
import { ImageContainerProps } from "./image-container.types";
import { transparentPixel } from "../../../common/constants";

const ImageContainer: React.FC<ImageContainerProps> = ({ src, alt = "image", className = "", transformation = "c_scale,w_100,dpr_2" }) => (
  <img src={src?.length > 0 ? addCloudinaryTransformation(src, transformation) : transparentPixel} alt={alt} className={className} />
);

export default ImageContainer;
