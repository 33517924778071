import React, { useEffect, useRef, useState } from "react";
import ImageContainer from "../../components/common/image-conatiner/image-container";
import SocialMediaButtons from "../../components/common/social-media-buttons/social-media-buttons";
import Button from "../../components/common/button";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../routes/routes.constant";
import DetailsTable from "../../components/detailsTable";
import {
  generateCloudinaryImageUrl,
  generateCloudinaryVideoThumbnail,
  generateCloudinaryVideoUrl,
  getLocalStorage
} from "../../utils/common-function";
import scroll from "../../utils/scroll";
import { SampleEligibleBrandsData } from "../../types";
import VolumeMute from "./../../assets/images/Mute.svg";
import VolumeFull from "./../../assets/images/volume-full.svg";
import mixpanel from "../../services/mixpanelService";
import { ILocationState } from "../../components/profileMenu/types";
import { maxGamePlayCount } from "../../common/constants";
import MatchSponsor from "./matchSponsor";
import clsx from "clsx";
import BrandHeader from "../../components/BrandHeader";

const SponsorDetails: React.FC = () => {
  const navigation = useHistory();
  const location = useLocation<ILocationState>();
  const shouldShowMatchModal = (location.state as { showMatchModal?: boolean })?.showMatchModal ?? true;
  const [showSponsorLoading, setShowSponsorLoading] = useState((location.state as { showMatchModal?: boolean })?.showMatchModal ?? true);
  const gamePlayCount = getLocalStorage("gamePlayCount") as number;
  const brandsData = getLocalStorage("brandsData") as SampleEligibleBrandsData;
  const brand = brandsData?.sampleEligibleBrands?.brand;
  const topEligibleBrands = brandsData?.sampleEligibleBrands?.topEligibleBrands;
  const stashlink = getLocalStorage("stashlink");
  const campaign = stashlink.campaign;
  const [muted, setMuted] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const state = location.state as { fromValidFlow: boolean; showMatchModal: boolean };
    if (!state?.fromValidFlow) {
      const stashLink = getLocalStorage("stashLink");
      navigation.push(stashLink ? stashLink : "/");
    }
  }, [location.state]);

  const handleVideoPlayback = () => {
    if (document.hidden) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVideoPlayback);
    mixpanel.track("brand-profile-viewed", { brandName: brand?.name });

    return () => {
      document.removeEventListener("visibilitychange", handleVideoPlayback);
    };
  }, []);
  
  const handleVideoPlaybackOnFocus = () => {
    if (!videoRef.current) return;
  
    if (document.hasFocus()) {
      videoRef.current.play(); // Play the video if the document is focused
    } else {
      videoRef.current.pause(); // Pause the video if the document is not focused
    }
  };
  
  useEffect(() => {
    const focusHandler = () => handleVideoPlaybackOnFocus(); // Handle play on focus
    const blurHandler = () => handleVideoPlaybackOnFocus();  // Handle pause on blur
  
    // Add event listeners for focus and blur
    window.addEventListener('focus', focusHandler);
    window.addEventListener('blur', blurHandler);
  
    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener('focus', focusHandler);
      window.removeEventListener('blur', blurHandler);
    };
  }, []);

  const handleMuteToggle = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.stopPropagation();
    setMuted((prevMuted) => {
      mixpanel.track("sound-clicked", { location: "brand-profile-page", muted: !prevMuted, brandName: brand?.name });
      if (prevMuted && videoRef.current) {
        videoRef.current.muted = false;
        videoRef.current.play();
      } else if (videoRef.current) {
        videoRef.current.muted = true;
      }
      return !prevMuted;
    });
  };

  useEffect(() => {
    if (shouldShowMatchModal) {
      scroll.lock();
      const timer = setTimeout(() => {
        setShowSponsorLoading(false);
        scroll.enable();
      }, 5500);
      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (shouldShowMatchModal && !showSponsorLoading) {
      setIsReady(true);
    }
  }, [showSponsorLoading]);

  useEffect(() => {
    if ((location.state as { showMatchModal?: boolean })?.showMatchModal === false) {
      setIsReady(true);
    }
  }, [(location.state as { showMatchModal?: boolean })?.showMatchModal]);

  return (
    <div className="min-h-screen flex flex-col bg-soft-black text-white">
      <div className="flex-grow pb-8">
        <BrandHeader brand={brand} isReady={isReady} />

        <div
          className={clsx(
            "transform transition-all duration-700 ease-in-out delay-200",
            isReady ? "translate-y-0 opacity-100" : "translate-y-full opacity-0"
          )}>
          {brand?.videoUrl ? (
            <div className="relative w-full">
              <video
                playsInline
                ref={videoRef}
                poster={generateCloudinaryVideoThumbnail(brand?.videoUrl, "480", "365", 1)}
                // Use c_fit to remove height constraint
                src={generateCloudinaryVideoUrl(brand?.videoUrl ?? "", "500", "500", "c_crop,g_center")} // "480", "365", "c_pad,b_blurred:400:15")}
                className="w-full object-cover border-t-2 border-b-2 border-soft-black"
                muted={muted}
                autoPlay
                loop
              />

              <div className="absolute top-2 right-2 h-8 w-8 rounded-full flex justify-center items-center p-2 bg-black/30 backdrop-filter-blur">
                <img src={muted ? VolumeMute : VolumeFull} alt={VolumeFull} onClick={handleMuteToggle} />
              </div>
            </div>
          ) : (
            <img
              key={brand?.imageUrl}
              src={brand?.heroImageUrl ? generateCloudinaryImageUrl(brand?.heroImageUrl || "", "480", "365") : ""}
              alt="brand images"
              className="w-full aspect-video object-cover border-t-2 border-b-2 border-soft-black"
            />
          )}

          <div className="flex flex-row gap-px overflow-x-auto whitespace-nowrap">
            {brand?.brandImages
              ?.slice(0, 10)
              .map((image, id) => (
                <ImageContainer
                  key={id}
                  transformation="c_auto,h_120,w_120"
                  src={image.imageUrl}
                  alt="brand images"
                  className="w-full h-[72px] object-cover border-r border-soft-black"
                />
              )) || null}
          </div>
          <div className="flex flex-col gap-6 p-5">
            <p className="font-secondary text-neutral-50 text-sm text-base-white/90 font-normal leading-tight-18.2">{brand?.heroTagline}</p>
            <div className="flex flex-col gap-2 bg-grey-bg p-4 pt-3 rounded-2xl">
              <h1 className="font-bold font-primary text-22px text-base-white">Our Story</h1>
              {brand?.description && (
                <p
                  className="font-secondary text-base-white/80 text-sm font-normal leading-[18.2px] "
                  dangerouslySetInnerHTML={{
                    __html: brand?.description.replaceAll("\r\n", "<br/>")
                  }}></p>
              )}
            </div>
            {brand?.website && (
              <div
                onClick={() =>
                  mixpanel.track("brand-website-clicked", {
                    brandName: brand?.name
                  })
                }
                className="w-[108px] h-10 flex justify-center items-center border border-base-white/80 rounded-lg">
                <a
                  href={brand?.website.startsWith("http") ? brand.website : `https://${brand?.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-transparent text-center text-sm text-base-white/80 font-primary font-semibold">
                  Website
                </a>
              </div>
            )}

            <div className="flex flex-row gap-4">
              <SocialMediaButtons
                screen="brand"
                facebookUsername={brand?.facebookUsername}
                instagramUsername={brand?.instagramUsername}
                twitterUsername={brand?.twitterUsername}
                youtubeUsername={brand?.youtubeUsername}
                tiktokUsername={brand?.tiktokUsername}
                linkedinUrl={brand?.linkedinUrl}
              />
            </div>
          </div>

          <DetailsTable
            items={[
              { label: "Registered name", value: brand?.name || "" },
              // { label: "Joined", value: convertDateToMonthYear(brand?.joinedAt) },
              { label: "Headquarters", value: brand?.location || "" },
              { label: "Founded in", value: brand?.yearFounded || "" }
            ]}
          />
          {campaign?.cause?.name && (
            <div className="p-4 pb-20">
              <span
                onClick={() =>
                  mixpanel.track("cause-hashtag-clicked", {
                    brandName: brand?.name,
                    campaignName: campaign?.title,
                    causeName: campaign?.cause?.name
                  })
                }
                className="border border-base-white/10 text-base-white/80 text-sm leading-tight-16.8 font-primary font-medium bg-grey/10 px-3 py-1 rounded-2xl text-center inline-block">
                #{campaign?.cause?.name}
              </span>
            </div>
          )}
        </div>
      </div>
      {shouldShowMatchModal ? (
        <div
          className={clsx([
            "absolute top-0 z-[1000] h-screen w-screen flex flex-col transition-transform duration-300 ease-out",
            showSponsorLoading ? "block" : "hidden"
          ])}>
          <MatchSponsor sponsorImageUrl={brand?.imageUrl} brandName={brand?.name || ""} eligibleBrands={topEligibleBrands} />
        </div>
      ) : null}
      {gamePlayCount < maxGamePlayCount ? (
        <div className="fixed bottom-0 w-full flex p-4 px-4 pb-5 items-center justify-center bg-black/70 backdrop-blur-8">
          <div className="w-full sponsor-page-bottom-btn">
            <Button
              title="Start the game"
              isDisabled={false}
              onClick={() => {
                mixpanel.track("brand-play-game-button-clicked", {
                  brandName: brand?.name,
                  campaignName: campaign?.title
                });
                navigation.replace({ ...location, state: {} });
                navigation.replace(routes.bubblePop, { fromValidFlow: true });
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SponsorDetails;
