import clsx from "clsx";
import mixpanel from "../services/mixpanelService";
import { Brand } from "../types";
import { generateCloudinaryImageUrl } from "../utils/common-function";
import ImageContainer from "./common/image-conatiner/image-container";

const BrandHeader = ({ brand, isReady }: { brand?: Brand; isReady?: boolean }) => (
  // const location = useLocation<ILocationState>();
  // const navigation = useHistory();
  // const handleNavigation = () => {
  //   navigateWithState(location, navigation);
  // };

  <div className="flex w-screen justify-center bg-soft-black py-2">
    <div className="w-full gap-8 text-white bg-soft-black rounded-lg">
      <div className="w-full h-12 py-3 flex items-center relative z-10">
        {/* <IoIosArrowBack className="text-gray-400 h-6 w-6 absolute left-0 top-3" onClick={handleNavigation} /> */}
        <div
          onClick={() =>
            mixpanel.track("brand-logo-clicked", {
              brandName: brand?.name
            })
          }
          className={clsx(
            "transform transition-all duration-700 ease-in-out left-3 flex justify-center items-center rounded-full border-8 border-soft-black relative top-4",
            isReady ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"
          )}>
          <ImageContainer
            src={brand?.imageUrl ? generateCloudinaryImageUrl(brand?.imageUrl || "", "176", "176") : ""}
            className={clsx("w-c-84 h-c-84 object-cover bg-white rounded-full border-2 border-base-white")}
            alt=""
          />
        </div>
        <p
          className={clsx(
            "transform transition-all duration-700 ease-in-out delay-100 ml-7 w-1/2 font-secondary text-base text-base-white font-normal leading-tight-20.8",
            isReady ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
          )}>
          <div className="font-normal text-base text-white text-shadow-game">100% sponsored by</div>
          <div className="font-bold text-base text-white text-shadow-game">{brand?.name}</div>
        </p>
      </div>
    </div>
  </div>
);
export default BrandHeader;
