const ButtonStyle = () => {
  //TODO: will update more tailwind css here
  const containerClass = "w-full gap-8.01 rounded-lg h-c-52";
  const baseStyle =
    "w-full h-12 px-4 py-2.5 text-base font-primary tracking-wider font-wght-750 leading-tight-19.2 gap-2 rounded-lg box-border select-none";
  const enabledStyle = baseStyle + " bg-primary-l1 border-2 border-black shadow-[0px_4px_0px_#6C801F] text-black";
  const disabledStyle = baseStyle + " bg-disabled text-grey";

  return { enabledStyle, disabledStyle, containerClass };
};

export default ButtonStyle;
